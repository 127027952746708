import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import { useRouterParam } from 'hooks/useRouterParam';
import { useCookies } from 'react-cookie';
import { wishlistKeys } from '.';
import config from '../../config';
import {
  fetchWishlist,
  fetchWishlistProducts,
  fetchWishlistProductsByCodes,
  fetchWishlistRealtimeInfo,
  fetchWishlistShareLinks,
} from './connector';

const useAllWishlist = () => {
  const { anonymous } = useAuthResolver();

  return useQuery({
    enabled: !anonymous,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWishlist(),
    queryKey: wishlistKeys.wishlists(),
    refetchOnWindowFocus: false,
  });
};

const useWishlistDetail = (wishlistId?: string, productCode?: string) => {
  const {
    query: { id: queryId },
  } = useRouter();
  const id = Array.isArray(queryId) ? queryId[0] : queryId;

  return useQuery({
    enabled: !!id || !!wishlistId,
    queryFn: () => fetchWishlist(wishlistId || id, productCode),
    queryKey: wishlistKeys.wishlistDetail(wishlistId || id, productCode),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useWishlistRealtimeInfo = (productCodes: string[]) =>
  useQuery({
    enabled: !!productCodes.length,
    queryFn: () => fetchWishlistRealtimeInfo({ productCodes }),
    queryKey: wishlistKeys.wishlistsRealtimeInfoCodes({ productCodes }),
  });

const useWishlistProducts = (wishlistId?: string) => {
  const idParam = useRouterParam('id');
  const id = wishlistId || idParam;
  const currentPageParam = useRouterParam('currentPage');
  const currentPage = currentPageParam ? parseInt(currentPageParam) : 0;

  return useQuery({
    enabled: !!id,
    placeholderData: keepPreviousData,
    queryFn: id ? () => fetchWishlistProducts({ currentPage, wishlistId: id }) : skipToken,
    queryKey: wishlistKeys.wishlistProducts(id, currentPage),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useWishlistShareLinks = ({ enabled = false }) => {
  const wishlistId = useRouterParam('id') ?? '';

  return useQuery({
    enabled: !!wishlistId && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWishlistShareLinks({ wishlistId }),
    queryKey: wishlistKeys.wishlistSharingLinks(wishlistId),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

const useAnonymousWishlistProducts = () => {
  const [cookies] = useCookies();
  const anonymousWishlistProducts = (cookies[config.wishListCookie.key] as string[] | undefined) ?? [];

  return useQuery({
    enabled: anonymousWishlistProducts !== undefined,
    placeholderData: keepPreviousData,
    queryFn: () => fetchWishlistProductsByCodes({ productCodes: anonymousWishlistProducts }),
    queryKey: wishlistKeys.anonWishlistProducts(anonymousWishlistProducts),
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
};

export {
  useAllWishlist,
  useAnonymousWishlistProducts,
  useWishlistDetail,
  useWishlistProducts,
  useWishlistRealtimeInfo,
  useWishlistShareLinks,
};
