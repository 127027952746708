import { useMutation, useQueryClient } from '@tanstack/react-query';
import { wishlistKeys } from '.';
import {
  addProductToWishlist,
  addWishlist,
  editWishlist,
  removeProductFromWishlist,
  removeWishlist,
  removeWishlistProduct,
} from './connector';

const useMutateEditWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editWishlist,
    onSuccess: ({ code }) => {
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlistDetail(code) });
    },
  });
};

const useMutateRemoveWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlists() });
    },
  });
};

const useMutateRemoveProductFromWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeWishlistProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [wishlistKeys.wishlistProducts()[0]],
      });
    },
  });
};

const useMutateAddWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlists() });
    },
  });
};

const useMutateAddProductToWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addProductToWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [wishlistKeys.wishlistDetail()?.[0]],
      });
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlistsRealtimeInfo() });
    },
  });
};

const useMutateRemoveProductToWishlist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeProductFromWishlist,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [wishlistKeys.wishlistDetail()?.[0]],
      });
      queryClient.invalidateQueries({ queryKey: wishlistKeys.wishlistsRealtimeInfo() });
    },
  });
};

export {
  useMutateAddProductToWishlist,
  useMutateAddWishlist,
  useMutateEditWishlist,
  useMutateRemoveProductFromWishlist,
  useMutateRemoveProductToWishlist,
  useMutateRemoveWishlist,
};
